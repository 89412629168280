body {
  /* padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left); */
  background-color: white;
}

html {
  scroll-behavior: smooth;
}

.Homepage {
  /* height: 100vh; */
  color: white;
  font-family: "Poppins", sans-serif;
}

/*TEST ZWECKE*/
/* .Homepage header,
.About {
  display: none !important;
} */

/* .Services {
  display: none !important;
} */

.Homepage section {
  z-index: -1;
  padding: 0 15px;
}

.Homepage header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 600px;
  width: 100%;
  background: linear-gradient(#0a093c, #03022b);
  padding: 0 15px;
}

.About {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 70px;
  background-color: #f6f6f6;
  color: black;
  height: 100vh;
  min-height: 900px;
  width: 100%;
}

.AboutTitle {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 30px;
  margin-top: 50px;
}

.AboutInformation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.awards {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 51%;
  min-width: 350px;
  margin-left: 10px;
}

.award {
  display: grid;
  align-items: center;
  column-gap: 20px;
  grid-template-columns: 1fr 10fr;
}

.award h5 {
  grid-column: 2;
}

.Services {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 700px;
  background-color: #222166;
}

.ServiceContainer {
  padding-top: 30px !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  width: 95%;
  max-width: 950px;
  height: max-content;
  margin: 0 auto;
}

.InfoContainers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* gap: clamp(30px, 2vw, 50px); */
  /* grid-template-columns: repeat(3, 300px);
  grid-template-rows: 320px 180px; */
}

.InfoContainer {
  margin: 10px;
  padding: 30px;
  flex: 1 1;
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background-color: #04052e;
  border-radius: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.9);
}

.Homepage .InfoContainer h3 {
  font-size: clamp(1.2rem, 3vw, 2.5rem);
}

.InfoContainer h5 {
  font-size: clamp(1rem, 2.5vw, 1.4rem);
  text-overflow: ellipsis;
}

.InfoContainer:last-child {
  grid-column: -1 / 1;
}

.chewy {
  font-family: "Chewy", system-ui;
  text-transform: uppercase;
  cursor: pointer;
}

.schoolbell {
  font-family: "Schoolbell", sans-serif;
  font-weight: normal;
}

.logo {
  font-size: 24px;
}

.background {
  position: absolute;
  background-image: url("../../static/img/background-purple.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-blend-mode: lighten;
  opacity: 0.25;
  color: white;
  z-index: 0;
}

.MainTitle h1 {
  text-align: center;
}

.Homepage h1 {
  font-size: clamp(1.5rem, 5vw, 5rem);
  margin: 0 !important;
}

.Homepage h2 {
  font-size: clamp(1.5rem, 4vw, 4rem);
  text-align: center;
}

.Homepage h3 {
  font-weight: normal;
  font-size: 36px;
}

.Homepage h4 {
  font-size: clamp(1.3rem, 3vw, 2.7rem);
  font-weight: 600;
}

.Homepage h5 {
  font-size: clamp(0.8rem, 2.5vw, 1.3rem);
  font-weight: 300;
}

.Homepage b {
  font-weight: 500;
}

.MainTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SubTitle {
  text-align: center;
  font-size: clamp(1rem, 2.5vw, 1.4rem);
  padding: 0 10px;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  z-index: 1;
}

.highlight {
  color: #ffb700;
}

.title-highlight {
  color: #acaaf5;
}

.CTA {
  color: rgba(0, 0, 0, 0.847);
  position: relative;
  font-weight: bold;
  border-radius: 40px;
  width: 180px;
  height: 57px;
  background-color: #acaaf5;
  border: none;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.CTA:hover {
  background-color: #7b78c9;
}

.blob {
  position: absolute;
  z-index: -1;
}

.Homepage header .blob {
  z-index: 0;
}

.blob:first-child {
  top: 0;
  left: 0;
}

.blob:nth-child(2) {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.blob:nth-child(3) {
  top: 0;
  left: 100%;
  transform: translateX(-100%);
}

.blob:nth-child(4) {
  top: 100%;
  transform: translateY(-100%);
  left: 0;
}

.blob:nth-child(5) {
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.blob:nth-child(6) {
  top: 100%;
  left: 100%;
  transform: translate(-100%, -100%);
}

.Homepage header .blob:nth-child(1) {
  top: 100%;
  transform: translateY(-100%);
  left: 0;
}

.Homepage header .blob:nth-child(2) {
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.Homepage header .blob:nth-child(3) {
  top: 100%;
  left: 100%;
  transform: translate(-100%, -100%);
}

/* .Booking .blob {
  z-index: 0;
} */

.Services .blob:nth-child(5),
.Booking .blob:nth-child(5) {
  top: 100%;
  left: 100%;
  transform: translate(-100%, -100%);
}

.label {
  position: absolute;
  font-weight: normal;
  color: white;
  transform: rotate(10deg);
  top: -30px;
  right: -70px;
}

.Homepage nav {
  border-radius: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  z-index: 20;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 10%;
  min-height: 60px;
  max-height: 90px;
  width: 100%;
  background: linear-gradient(rgba(10, 9, 60, 0.6), #f6f6f600);
  z-index: 100;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  transition: background-color 0.3s ease;
}

.overlay.dark {
  /* background: linear-gradient(#03022b, rgba(0, 0, 0, 0.7)); */
  background-color: #03022b;
}

.Homepage .links {
  width: max-content;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 40px;
  cursor: pointer;
}

.Homepage .links a::before {
  position: absolute;
  content: "";
  top: 100%;
  left: 0;
  background-color: #acaaf5;
  width: 0%;
  height: 2px;
  transition: width 0.3s ease;
}

.Homepage .links a:hover::before {
  width: 100%;
}

.Homepage .links a {
  position: relative;
}

.description {
  position: absolute;
  right: -50px;
  bottom: -10px;
}

.arrow {
  transform: rotate(180deg);
}

.Homepage a:link,
.Homepage :visited {
  color: white;
  text-decoration: none;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.icons a:link,
.icons a:visited {
  color: #fff;
  transition: color 0.3s ease;
}

.icons a:hover,
.icons a:active {
  color: #acaaf5;
  cursor: pointer;
}

/*make sticky*/

/* .Homepage header,
.Homepage section {
  padding: 30px;
  height: 100vh;
  position: sticky;
  top: 0;
} */

.menu-burger {
  position: fixed;
  top: 3%;
  right: 3%;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #acaaf5aa;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: none;
  z-index: 100;
}

.menu-icon {
  position: relative;
  width: 20px;
  height: 10px;
  z-index: 10;
}

.menu-icon::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 2.5px;
  background-color: #f6f6f6;
  transition: all 0.3s ease;
}

.menu-icon::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2.5px;
  background-color: #f6f6f6;
  transition: all 0.3s ease;
}

.menu-icon.toggled::before {
  transform: translateY(3px) rotate(45deg);
}

.menu-icon.toggled::after {
  transform: translateY(-4px) rotate(-45deg);
}

.menu-icon:hover::after,
.menu-icon:hover::before {
  background-color: #dbdbee;
}

.sidebar {
  position: fixed;
  background-color: #403e94aa;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  height: 100%;
  width: 50%;
  top: 0;
  right: -60%;
  z-index: 3;
  transition: 0.5s;
  display: none;
  box-shadow: -10px 0 10px #dde5eb10;
}

.sidebar.toggled {
  right: 0;
}

.sidebar-links {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.sidebar a {
  position: relative;
  color: white;
  padding: 10px 20px;
}

.sidebar a::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 2px;
  background: #acaaf5;
  transition: width 0.3s ease;
}

.sidebar a:hover::before {
  width: 100%;
}

.sidebar a:hover {
  background-color: #acaaf540;
}

@media (max-width: 1150px) {
  .icons,
  .logo {
    display: none;
  }
}

@media (max-width: 1050px) {
  .Homepage img {
    transform: scale(0.8);
  }
}
@media (max-width: 870px) {
  .blob {
    display: none;
  }

  .Homepage img {
    transform: scale(0.7);
  }

  .Services {
    height: 80vw;
  }

  .AboutTitle {
    text-align: center;
    margin-left: 0;
  }
}

@media (max-width: 450px) {
  .description {
    top: 100%;
    right: 2%;
  }
  .label {
    left: 50px;
    top: -10px;
  }

  .overlay {
    display: none;
  }

  .menu-burger {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
  }
}
