.body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 20px;
  height: 100vh;
}

.list-container {
  overflow: auto;
  max-height: 70vh;
}

.week-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.Container {
  max-width: 1000px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.h1 {
  text-align: center;
  color: #333;
}

.table-button {
  width: 100px;
  margin: 0;
}

.table-container {
  width: 100%;
  max-height: 300px;
  overflow: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table th,
.table td {
  padding: 12px 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #f4f4f4;
  color: #333;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.overlay {
  display: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000060;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.button {
  padding: 8px 12px;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  cursor: pointer;
  text-align: center;
}

.button:hover {
  background-color: #0056b3;
}

.button2 {
  background-color: #ff3300;
}

.button2:hover {
  background-color: #ff0000;
}

@media (max-width: 900px) {
  .table th,
  .table td {
    padding: 5px;
  }
}
