.LegalContainer {
  background-color: white;
  height: 100vh;
  width: 100vw;
  color: black;
  font-family: "Poppins", sans-serif;
  padding: 20px;
}

.Imprint {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin: 0 auto;
  text-align: center;
}

.PrivacyPolicy {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}

.backRef {
  color: black;
  position: absolute;
  display: flex;
  align-items: center;
  gap: 5px;
  bottom: -30px;
  z-index: 2;
  transition: gap 0.5s ease;
  cursor: pointer;
}

.backRef:hover {
  gap: 10px;
}

.backRef a:link,
.backRef a:visited {
  text-decoration: none;
}

.companyInformation {
  position: relative;
}

.Contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  min-height: 700px;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
}

.Contact h5,
.Contact p {
  text-align: center;
}

.LegalContainer b {
  font-weight: 500;
  color: black;
}

.LegalContainer h1 {
  font-size: 72px;
  margin: 0 !important;
}

.LegalContainer h2 {
  font-size: 64px;
}

.LegalContainer h3 {
  font-weight: 600;
  font-size: 42px;
}

.PrivacyPolicy h3 {
  margin-top: 50px;
}

.title:hover {
  color: rgb(52, 71, 215);
}

.LegalContainer h4 {
  font-size: 32px;
  font-weight: 500;
  margin: 30px 0;
  vertical-align: middle;
}

.LegalContainer h5 {
  font-size: 18px;
  font-weight: 300;
}

.LegalContainer p {
  color: #484848;
  margin: 10px 0;
  line-height: 24px;
}

.LegalContainer textarea {
  resize: none;
  display: block;
  outline: none;
  border: 1px solid black;
  border-radius: 12px;
  padding: 10px;
  width: 100%;
  height: 160px;
}

.LegalContainer input {
  outline: none;
  border: 1px solid black;
  border-radius: 12px;
  height: 30px;
  width: 100%;
  padding: 10px;
}

.Themes > li {
  border-top: 1px solid #d2d2d7;
  min-height: 105px;
}

.Themes section {
  display: none;
  width: 95%;
}

.Themes section.active {
  display: block;
}

section li {
  margin: 10px 0;
  margin-left: 20px;
}

.Plus {
  transition: all 0.3s ease;
}

.Plus.active {
  transform: rotate(45deg);
}

.Themes {
  width: 80%;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.policyTitle {
  position: relative;
}

.contactForm {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  background-color: #fafafa;
  border-radius: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
}

.Contact .errorMsg {
  overflow-wrap: break-word;
  word-wrap: break-word;
  height: min-content;
  margin-bottom: 15px;
}

.LegalContainer a {
  color: black;
}

.LegalContainer button {
  outline: none;
  border: none;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #007bff;
  color: #fafafa;
  font-weight: 500;
}

.LegalContainer button:hover {
  background-color: #0056b3;
}

/* @media (max-width: 700px) {
  .LegalContainer {
    width: 500px;
    margin: 0 auto;
    height: max-content;
  }
  .LegalContainer h4 {
    font-size: 26px;
  }

  .LegalContainer h3 {
    font-size: 33px;
  }

  .Contact {
    padding: 0 7.5%;
  }
} */

@media (max-width: 650px) {
  .PrivacyPolicy {
    width: 500px;
    margin: 0 auto;
  }

  .LegalContainer h3 {
    font-size: 33px;
  }

  .LegalContainer h4 {
    font-size: 26px;
  }

  .Contact {
    width: 100%;
  }

  .Contact h5,
  .Contact p {
    font-size: 14px;
  }

  .contactForm {
    width: 85%;
  }
}
