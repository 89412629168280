.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 100px;
  align-items: center;
  font-family: Helvetica, sans-serif;
  background-color: #f7f7f7;
  height: 100vh;
  font-family: "Poppins", Helvetica, sans-serif;
  padding: 0 20px;
}

.title {
  text-align: center;
}

.p {
  text-align: center;
}

.body h1 {
  font-size: 72px;
  margin: 0 !important;
}

.body h2 {
  font-size: 64px;
}

.body h3 {
  font-weight: normal;
  font-size: 36px;
}

.body h4 {
  font-size: clamp(24px, 5vw, 42px);
  font-weight: 600;
}

.body h5 {
  font-size: clamp(14px, 3vw, 18px);
  font-weight: 300;
}

.message-container {
  position: relative;
  display: flex;
  flex: 0 0 50wv;
  flex-direction: column;
  gap: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
}

.h1 {
  font-size: 40px;
  font-family: "Chewy", system-ui;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 3px;
}

.body input {
  outline: none;
  font-size: 18px;
  color: #0e0d45;
  font-family: inherit;
  width: 100%;
  padding: 10px;
  border: 1px solid #1d198a;
  border-radius: 10px;
  transition: border-color 0.3s ease;
  transition: box-shadow 0.5s ease;
}

.a:link,
.a:visited {
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  color: #007bff;
  padding: 5px 10px;
  text-decoration: none;
  transition: background-color 0.5s ease-in-out;
  text-align: center;
}

.a:hover {
  background-color: #ddd;
  color: #0056b3;
}

.body button {
  font-weight: 500;
  border-radius: 40px;
  height: 40px;
  padding: 5px 10px;
  background-color: #bfcbd4;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.body button:hover {
  background-color: #ddd;
}

.backRef {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 5px;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  transition: gap 0.5s ease;
  cursor: pointer;
}

.backRef:hover {
  gap: 10px;
}

.backRef a:link,
.backRef a:visited {
  color: white;
  text-decoration: none;
}
