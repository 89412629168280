.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 100px;
  align-items: center;
  font-family: Helvetica, sans-serif;
  color: #dde5eb;
  background-color: #222166;
  height: 100vh;
  font-family: "Poppins", Helvetica, sans-serif;
  padding: 0 20px;
}

.title {
  text-align: center;
}

.message-container {
  position: relative;
  display: flex;
  flex: 0 0 50wv;
  flex-direction: column;
  gap: 20px;
  background-color: #04052e;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.9);
  width: 90%;
  max-width: 400px;
}

.body h4 {
  font-size: clamp(24px, 5vw, 42px);
  font-weight: 600;
}

.body h5 {
  font-size: clamp(14px, 3vw, 18px);
  font-weight: 300;
}

.body input {
  outline: none;
  font-size: 18px;
  color: #0e0d45;
  font-family: inherit;
  width: 100%;
  background-color: #acaaf5;
  padding: 10px;
  border: 1px solid #1d198a;
  border-radius: 10px;
  transition: border-color 0.3s ease;
  transition: box-shadow 0.5s ease;
}

.body input::placeholder {
  color: #212026;
}

.body button {
  font-weight: 500;
  border-radius: 40px;
  height: 40px;
  padding: 5px 10px;
  background-color: #acaaf5;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.body button:hover {
  background-color: #7b78c9;
}

.backRef {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 5px;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  transition: gap 0.5s ease;
  cursor: pointer;
}

.backRef:hover {
  gap: 10px;
}

.backRef a:link,
.backRef a:visited {
  color: white;
  text-decoration: none;
}
