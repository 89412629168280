.errorContainer {
  position: fixed;
  top: -100vh;
  right: 10px;
  left: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 95%;
  max-width: 400px;
  height: 100px;
  background-color: #04052e;
  color: white;
  border-radius: 10px;
  padding: 30px 36px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.14);
  transition: top 1s ease;
}

.errorMsg {
  font-family: "Poppins", sans-serif;
  word-wrap: break-word;
  text-align: center;
}

.close {
  position: absolute;
  top: 10px;
  left: 10px;
}

.errorContainer.opened {
  top: 10px;
}

.errorContainer a {
  color: #ea920f;
}
