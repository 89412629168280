.Booking {
  position: relative;
  height: 100vh;
  min-height: 800px;
  background-color: #222166;
  color: white;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  overflow: hidden;
}

.InputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;
  width: 90vw;
  max-width: 500px;
  background-color: #04052e;
  border-radius: 16px;
  padding: 30px 36px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.9);
  overflow-x: visible;
}

.bookingContainer {
  height: 80%;
  width: 100%;
}

.backButton {
  position: absolute;
  top: 50%;
  left: -20%;
  transform: translate(50%, -20%);
  width: 50px;
  height: 50px;
  background-color: #acaaf5;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.dateArrow {
  transition: color 0.3s ease;
}

.backRef {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 5px;
  bottom: -30px;
  z-index: 2;
  transition: gap 0.5s ease;
}

.backRef:hover {
  gap: 10px;
}

.backRef a:link,
.backRef a:visited {
  color: white;
  text-decoration: none;
}

.dateArrow:hover {
  color: #acaaf5;
}

.backButton:hover {
  background-color: #7b78c9;
}

.backIcon {
  transition: transform 0.5s ease;
}

.backButton:hover .backIcon {
  transform: translateX(-3px);
}

.step1,
.step2,
.step3 {
  transform: translate(-50%, -50%);
  transition: left 1s ease;
  position: absolute;
  top: 50%;
  left: 50%;
}

.step2.transition0 {
  pointer-events: none;
  left: 200%;
}

.step3.transition0 {
  pointer-events: none;
  left: 400%;
}

.step1.transition1 {
  pointer-events: none;
  left: -200%;
}

.step3.transition1 {
  pointer-events: none;
  left: 200%;
}

.step1.transition2 {
  pointer-events: none;
  left: -400%;
}

.step2.transition2 {
  pointer-events: none;
  left: -200%;
}

.actionContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 90%;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.customerInformation {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.booking-title {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 80px;
}

.monthSelection {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.timeslots {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: flex-start;
  width: 100%;
  gap: 25px;
  /* grid-template-columns: repeat(4, 1fr); */
}

.timeslot {
  width: 85px;
  height: 40px;
}

.time-checkbox {
  display: none;
}

.time-label {
  border: 1px solid white;
  font-weight: 500;
  padding: 13px 0;
  display: block;
  background-color: #acaaf5;
  border-radius: 16px;
  width: 100%;
  color: black;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  user-select: none;
  filter: drop-shadow(0 0 5px #acaaf570);
}

.time-checkbox:checked + .time-label {
  background-color: #2d4495;
  color: white;
  border-color: wheat;
}

.time-checkbox:not(:checked):enabled + .time-label:hover {
  background-color: #7b78c9;
}

.dateFields {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #5a5972;
}

.dateField {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  width: 50px;
  border-bottom: 1px solid black;
  padding-bottom: 4px;
  transition: all 0.3s ease;
}

.dateField:hover {
  color: #dde5eb;
  border-color: #dde5eb;
}

.date-radio {
  display: none;
}

.date-radio:checked ~ label {
  color: #acaaf5;
}

.date-radio ~ label {
  font-family: "Poppins", sans-serif;
  text-align: center;
  user-select: none;
  transition: all 0.3s ease;
  width: 100%;
}

.dateField:has(.date-radio:checked) {
  border-bottom: 1px solid #acaaf5;
}

.errorMsg {
  user-select: none;
  color: #ffb700;
  grid-column: 1 / -1;
}

.time-label.selected {
  background-color: #203057;
  color: white;
}

.time-label.disabled {
  border: none;
  background-color: #acaaf520;
  color: #a5a5a590;
  cursor: not-allowed;
}

.Booking h1 {
  font-size: 72px;
  margin: 0 !important;
}

.Booking h2 {
  font-size: 64px;
}

.Booking h3 {
  font-weight: normal;
  font-size: 36px;
}

.Booking h4 {
  font-size: clamp(1.3rem, 3vw, 2.7rem);
  font-weight: 500;
}

.Booking h5 {
  font-size: clamp(0.8rem, 2vw, 1.3rem);
  font-weight: 300;
}

.Booking button {
  user-select: none;
  font-weight: 500;
  border-radius: 40px;
  height: 57px;
  width: 180px;
  background-color: #acaaf5;
  color: #04052e;
  border: none;
  cursor: pointer;
  filter: drop-shadow(0 5px 25px #acaaf525);
  transition: background-color 0.5s ease;
}

.Booking button:hover {
  background-color: #7b78c9;
}

.ThreeDots {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, 50%);
}

.Booking input {
  font-weight: 450;
  font-size: 18px;
  color: #0e0d45;
  font-family: inherit;
  background-color: #acaaf5;
  width: 100%;
  padding: 10px;
  border: 1px solid #1d198a;
  border-radius: 10px;
  transition: border 0.3s ease;
}

.Booking input[type="date"] {
  width: min-content;
}

.Booking input[type="email"],
.Booking input[type="password"] {
  height: 53px;
  outline: none;
}

.passwordInput {
  position: relative;
  width: 100%;
}

.passwordInput input[type="password"] {
  padding-right: 15%;
}

.tooltip {
  color: black;
  overflow: hidden;
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translateY(-50%);
  cursor: help;
}

.tooltip-text a {
  color: #ffb700;
}

.Booking input:focus {
  border: 1px solid white;
}

.Booking input::placeholder {
  color: #212026;
}

.Booking a {
  color: #ea920f;
  cursor: pointer;
}

.a:hover {
  color: #b77410;
}

.message {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 700px) {
  .backButton {
    top: 100%;
    left: 40%;
  }

  .booking-title {
    text-align: center;
    margin: 0;
    padding: 0 15px;
  }

  .bookingContainer {
    height: 90%;
  }
}
