.body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  min-height: 100vh;
  padding: 50px;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.customerDetails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
  column-gap: 15px;
}

.h1,
.h2 {
  color: #333;
  margin-bottom: 20px;
}

.customer-selection,
.customer-info,
.invoice-items {
  margin-bottom: 20px;
}

.label {
  font-weight: bold;
}

.select,
.input[type="text"],
.input[type="number"],
.input[type="datetime-local"],
.input[type="date"],
.input[type="time"],
.input[type="password"] {
  padding: 5px;
  margin: 5px 0;
  width: 100%;
  box-sizing: border-box;
}

.search-bar {
  position: relative;
  margin: 5px 0;
}

.search-bar ul {
  display: none;
  z-index: 1;
}

.search-bar input:focus + ul {
  display: block;
}

.search-bar ul:hover {
  display: block;
}

.search-bar .input {
  margin: 0 !important;
}

.search-bar ul {
  background-color: #e6e4e4;
  position: absolute;
  width: 100%;
}

.search-bar li {
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.search-bar li:hover {
  background-color: #eee;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.button-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .table,
th,
td {
  border: 1px solid #ddd;
} */

/* th,
td {
  padding: 10px;
  text-align: center;
} */

.button {
  padding: 7px 10px;
  color: #fff;
  background-color: #007bff;
  border: none;
  cursor: pointer;
  margin-right: 5px;
}

.table-button {
  width: 100%;
  margin: 0;
}

.button:hover {
  background-color: #0056b3;
}

.containers.login-container,
.containers.timeslot-container {
  top: 50%;
  transform: translate(-50%, -50%);
}

.containers {
  /* display: none; */
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.containers .input,
.containers .select {
  margin-bottom: 15px;
}

.appointmentContainer {
  top: 20%;
}

.containers h2 {
  text-align: center;
  margin-bottom: 20px;
}

.containers label {
  display: block;
}

.containers {
  margin-bottom: 20px;
  z-index: 2;
}

.containers button {
  width: 100%;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000060;
  backdrop-filter: blur(5px);
  --webkit-backdrop-filter: blur(5px);
  z-index: 1;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.nav > * {
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
}

.active {
  display: block;
}

.menu-burger {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 15px;
  width: 20px;
  cursor: pointer;
}

.menu-burger::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: black;
  transform: rotate(45deg) translateY(8.5px);
  z-index: 999;
}

.menu-burger::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: black;
  transition: 0.3s ease;
  z-index: 999;
  transform: rotate(-45deg) translateY(-8.5px);
}

@media (max-width: 900px) {
  .container {
    font-size: 90%;
  }

  .body {
    padding: 10px;
  }
}

@media (max-width: 450px) {
  .container {
    font-size: 70%;
  }

  .h1,
  .h2 {
    margin-bottom: 5px;
  }
  .body {
    padding: 5px;
  }
}
