* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  list-style: none;
  -webkit-padding-start: 0px;
}

html {
  overflow-x: hidden;
}

.body {
  position: relative;
  font-family: "Poppins", Helvetica, sans-serif;
  background-size: cover;
  background-color: #222166;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  overflow-x: hidden;
}

.body a {
  color: #ffb700;
}

/* .background {
  position: absolute;
  background-image: url("../../static/img/background-purple.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-blend-mode: lighten;
  opacity: 0.25;
  color: white;
  z-index: 0;
} */

.select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.section {
  display: flex;
  min-height: 800px;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  row-gap: 15px;
  column-gap: 10px;
}

.h1 a:link,
.h1 a:visited {
  color: inherit;
  text-decoration: none;
}

.h1 a:hover {
  color: #acaaf5;
}

.h1 a {
  transition: color 0.3s ease;
}

.main-header {
  font-family: "Poppins", sans-serif;
  display: flex;
  color: #fff;
  background-color: #403e94;
  height: 90px;
  align-items: center;
  justify-content: space-around;
  border-bottom: 2px solid #acaaf5;
}

.footer {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #403e94;
  color: #dde5eb;
  z-index: 1;
  border-top: 3px solid #acaaf5;
}

.top-footer {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}

.bottom-menu {
  display: flex;
  align-items: center;
  gap: 15px;
  align-self: flex-end;
  margin-bottom: 10px;
}

.copyright {
  border-top: 2px solid #acaaf5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 50px;
  font-size: min(2vw, 16px);
}

.bottom-menu a {
  color: #dde5eb;
  text-decoration: none;
  position: relative;
  font-size: clamp(11px, 3vw, 16px);
  cursor: pointer;
}

.nav {
  display: flex;
  gap: 15px;
}

.nav a {
  color: white;
  text-decoration: none;
  border: 1px solid white;
  padding: 5px 10px;
  border-radius: 100px;
  transition: color 0.3s ease;
}

.nav a:hover {
  color: #98beff;
  border-color: #98beff;
}

.span {
  color: #98beff;
}

.h5 {
  font-weight: normal;
  font-size: clamp(15px, 4vw, 20px) !important;
  flex: 1;
}

.h1 {
  font-size: clamp(0px, 5vw, 34px) !important;
  font-weight: normal;
  font-family: "Schoolbell", cursive;
  color: #dde5eb;
}

.chewy-font {
  font-family: "Chewy", system-ui;
  text-transform: uppercase;
}

.form-container {
  background-color: #403e94;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  /* max-height: 733px; */
  width: 400px;
}

.form-container h2 {
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 20px;
  color: #dde5eb;
}

.form-group {
  grid-column: 1 / -1;
}

.plz,
.birthdate {
  grid-column: span 3;
}

.town,
.name {
  grid-column: span 5;
}

.street,
.selection {
  grid-column: span 6;
}

.streetnumber,
.schoolyear {
  grid-column: span 2;
}

.checkbox-accept {
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: clamp(13px, 3vw, 15px);
}

.checkbox-accept .checkbox {
  margin-right: 0;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #dde5eb;
}

.error-message {
  color: #ffb700;
  text-align: center;
}

.req::after {
  content: "*";
  color: #ffb700;
}

.menu-burger {
  display: none;
  position: relative;
  /* background-color: red; */
  height: 15px;
  width: 26px;
}

.menu-burger::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: white;
  transition: 0.3s ease;
  z-index: 999;
}

.menu-burger.active::before {
  transform: rotate(45deg) translateY(8.5px);
}

.menu-burger.active::after {
  transform: rotate(-45deg) translateY(-8.5px);
}

.menu-burger:hover::before,
.menu-burger:hover::after {
  background-color: #98beff;
}

.menu-burger::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: white;
  transition: 0.3s ease;
  z-index: 999;
}

.form-group input,
.form-group textarea,
.form-group select {
  font-size: 14px;
  color: #0e0d45;
  font-family: inherit;
  background-color: #acaaf5;
  width: 100%;
  padding: 10px;
  border: 1px solid #1d198a;
  border-radius: 10px;
  transition: border-color 0.3s ease;
  transition: box-shadow 0.5s ease;
}

.input,
.select {
  display: block;
  height: 43px;
}

.input.shake,
.select.shake {
  animation: change-color 0.5s ease-in-out, shake 0.5s ease-in;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-2px);
  }
  50% {
    transform: translateX(2px);
  }
  75% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes change-color {
  to {
    border-color: #ffb700;
  }
}

.form-group input:not([type="checkbox"]):hover,
.form-group textarea:hover,
.form-group select:hover {
  box-shadow: 0 2px 10px rgba(221, 229, 235, 0.4);
}

.input[type="date"]::placeholder {
  color: #000;
}

.input[type="date"]::-webkit-input-placeholder {
  color: #000;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  border-color: #dde5eb;
  color: #1d198a;
  outline: none;
}

.form-group textarea {
  resize: vertical;
}

.form-group button {
  font-family: inherit;
  background-color: #acaaf5;
  color: #1d198a;
  font-size: 14px;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-group button:hover {
  background-color: #dde5eb;
}

/* PFEILE VON NUMBER INPUT ENTFERNEN */
.input[type="number"]::-webkit-inner-spin-button,
.input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::-webkit-datetime-edit,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

.input[type="date"] {
  padding-top: 12px;
  text-align: center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar {
  display: flex;
  flex-direction: column;
  background-color: #403e9480;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  align-items: flex-start;
  gap: 40px;
  justify-content: flex-start;
  position: absolute;
  top: 90px;
  right: -200px;
  height: 100%;
  min-height: 800px;
  width: 200px;
  box-shadow: -10px 0 10px #dde5eb10;
  transition: 0.3s;
}

.sidebar a:link,
.sidebar a:visited {
  position: relative;
  padding: 10px 20px;
  color: #dde5eb;
  text-decoration: none;
  width: 100%;
  transition: 0.3s;
}

.sidebar a::before,
.bottom-menu a::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 2px;
  background: #acaaf5;
  transition: width 0.3s ease;
}

.sidebar a:hover::before,
.bottom-menu a:hover::before {
  width: 100%;
}

.sidebar a:first-child {
  margin-top: 10px;
}

.sidebar a:hover {
  background-color: #acaaf540;
}

.sidebar.open {
  right: 0; /* Slide in */
}

.menubutton {
  display: none;
}

.menubar-options:hover {
  fill: #dde5eb;
  transition: 0.3s;
}

.menubar-options {
  transition: 0.3s;
}

@media (max-width: 950px) {
  .nav-link {
    display: none;
  }

  .bottom-menu {
    align-self: center;
  }

  .menu-burger {
    display: block;
  }

  .h5 {
    text-align: center;
    margin-top: 10px;
  }
  .top-footer {
    flex-direction: column;
  }
}

@media (min-width: 950px) {
  .sidebar.open {
    right: -250px;
  }
}

@media (max-width: 470px) {
  .main-header {
    flex-direction: row;
    justify-content: center;
    gap: 60px;
  }
}
